import { NgModule } from '@angular/core';
import { Routes, RouterModule  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
export const routes: Routes = [
   
    { 
        path: '',  loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'admin',  loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy'
})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }