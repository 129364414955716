import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';




@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  foods: any[];
  selectedValue: any ;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { 
                this.foods = this.data.listRol;
                this.selectedValue = this.data.rolSelected;
              }

  ngOnInit(): void {
  }

  onConfirm(): void { 
    this.dialogRef.close(this.selectedValue);
  }

  onDismiss(): void { 
    this.dialogRef.close(false);
  }


}
 