<mat-card class="card">
  <mat-card-header>
    <mat-card-title style="margin-top: 15px;">
      <span [innerHTML]="data?.message"></span>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>

      <mat-form-field *ngIf="data.select" appearance="outline">
        <mat-label>Seleccionar Rol:</mat-label>
        <mat-select [(ngModel)]="selectedValue">
          <mat-option *ngFor="let food of foods" [value]="food">
            {{food}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button *ngIf="data?.error!= null" color="primary" style="float: right;margin-left: 20px;"
            (click)="onConfirm()">{{data?.info}}</button>
    <button mat-raised-button *ngIf="data?.error == null" color="primary"
            style="float: right;margin-left: 20px;margin-top: -34px;" (click)="onConfirm()">{{data?.info}}</button>
    <button *ngIf="data?.error!= null" mat-raised-button color="warn" (click)="onDismiss()">{{data?.error}}</button>
  </mat-card-actions>
</mat-card>
