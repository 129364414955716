import {ConfirmDialogComponent} from "../confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";

export class Utils {
  public static confirmarDialogo(dialog:MatDialog, text: string) {
    const dialogRef = dialog.open(ConfirmDialogComponent, {
      data: {
        message: text,
        info: 'Aceptar',
        select: false
      },
      disableClose: true,
      panelClass: ['theme-dialog'],
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res != false) {
        dialogRef.close(null);
      }
    });
    return dialogRef;
  }
}
