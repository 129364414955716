
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog} from "@angular/material/dialog";
import {Utils} from "../../shared/utils/utils";

export class Messages  {
    constructor(private _snackBar: MatSnackBar) {}

    
    public openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action, {
        duration: 2000
      });
    }

      public openSnackBarError(message: string, action: string, dialog: MatDialog) {
        /*this._snackBar.open(message, action, {
          duration: 7000
        });*/
        Utils.confirmarDialogo(dialog, message);
      }

}
