import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
/**
   * objeto que captura el usuario y la contraseña ingresada por el usuario.
   */
 modelUser: any = {};
 /**
  * bandera que muestra y esconde la progressBar del formulario del login.
  */
 IsWait: boolean = false;
 /**
  * constructor
  * @param router libreria para redireccionar a otra url
  * @param authenticationService servicio de autenticacion 
  * @param userService servicio de usuarios 
  * @param snackBar libreria para mostrar mensajes en el sncackbar
  * @param loginService libreria de login service
  */
 constructor(private router: Router,
   public snackBar: MatSnackBar,
   private loginService: LoginService) {

 }

 /**
  * metodos que se ejecutan al iniciar el componente
  */
 ngOnInit() {
   sessionStorage.clear();
   sessionStorage.setItem("X-TenantID", "planea");
  //this.consultarTenant(this.getPrefijo(location.hostname));
 }

 /**
     *  configuracion del snackBar
     * @param message mensate que se muestra, ejemplo: "faltan campos"
     * @param action se muestra una accion a tomar o una alerta.
     */
 openSnackBar(message: string, action: string) {
   this.snackBar.open(message, action, {
     duration: 2000,
   });
 }

 /**
  * metodo que valida que los campos ingresado en el formulario no esten vacios
  * @param modelUser datos ingresador en el formulario de login
  */
 validarCampor(modelUser: any) {
   let isValid = true;
   if (!modelUser.username) {
     this.openSnackBar("El nombre de usuario no puede estar vacío", "!Cuidado!");
     isValid = false;
   }

   if (!modelUser.password) {
     this.openSnackBar("La contraseña no puede esta vacía", "!Cuidado!");
     isValid = false;
   }
   return isValid;
 }

 /**
  * metodo para iniciar sesion, primero genera el token, luego
  *  busca el usuario en la base de datos para guardarlo en el sessionStorage
  */
 login() {
   this.IsWait = true;
  

 }

 /**
  * metodo para redireccionar a la visualizacion 
  * de plan estrategico luego de logearse correctamente
  */
 private navigateAfterSuccess() {
   this.router.navigate(['visualizacion/plan']);
 }

}


