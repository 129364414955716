const login = (token, permisos, userId, nombreCompleto,rol, menu, usuarioLogin) => {
  localStorage.setItem('tokenAcceso', token);
  localStorage.setItem('userId', userId);
  localStorage.setItem('rol', rol);
  localStorage.setItem('usuarioLogin', usuarioLogin);
  localStorage.setItem('nombreCompleto', nombreCompleto);
  localStorage.setItem('menuSia3', JSON.stringify(menu));
  localStorage.setItem('permisos', JSON.stringify(permisos))
}
const logout = () => {
  localStorage.removeItem("tokenAcceso");
  localStorage.removeItem('permisos');
  localStorage.removeItem('userId');
  localStorage.removeItem('usuarioLogin');
  window.location.href=localStorage.getItem('urlLogin')
}
export { login, logout }