import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { logout } from './security-utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Messages } from './messages';
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  public messages :Messages;
    constructor( private spinner: NgxSpinnerService,private _snackBar: MatSnackBar, private dialog:MatDialog) {
      this.messages = new Messages(_snackBar);
    }

    intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      this.spinner.show();
      let token = localStorage.getItem("tokenAcceso");
      let userId = localStorage.getItem("userId");
      if (token && token.length > 0) {
        req = req.clone({
            setHeaders: {
              Authorization: `${token} ${userId}`,
            }
        });
      }
      return next.handle(req).pipe(map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.spinner.hide();
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            logout();
          }
          if (error != null) {
            if (error.status != 404) {
              if(error.status == 415){
                this.messages.openSnackBarError(error.error.mensaje, 'Error', this.dialog);
              }else if (error.status == 500){
                this.messages.openSnackBarError('En el momento existe un error en el sistema, por favor intente nuevamente.', 'Error', this.dialog);
              }
            }
          }
          this.spinner.hide();
          const started = Date.now();
          const elapsed = Date.now() - started;
          return throwError(error);
        })
      );
    }
}
