
<div class="login">
    <mat-card class="cardLogin" style="width: 400px;">
        
      <p-progressBar mode="indeterminate" [style]="{'height': '6px'}" *ngIf="IsWait"></p-progressBar>
      <br><br>
      <div class="botonDiv">
        <img  class="center" style="width: 150px;margin-top: 10px;border-radius: 50%;" src="Card" src="../../assets/images/logo.PNG">
        <br><br><br><br><br><br>

        <button  [style.background]="'#487685'" style="width: 100%;" (click)="login()" mat-raised-button color="primary"><mat-icon>directions_bike</mat-icon><span style="margin-left: 15px;">Eres Ciclista</span></button><br><br>
            <button  [style.background]="'#487685'" style="width: 100%;" (click)="login()" mat-raised-button color="primary"><mat-icon>account_box</mat-icon><span style="margin-left: 15px;">Eres Administrador</span></button>
       
            <div *ngIf="false">
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Usuario</mat-label>
            <input (keyup.enter)="login()" matInput placeholder="Ingresar usuario" name="usuario" type="text"
              [(ngModel)]="modelUser.username" required>
            <mat-icon matSuffix>account_circle</mat-icon>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 100%;margin-top: -20px">
            <mat-label>Clave</mat-label>
            <input (keyup.enter)="login()" matInput name="password" type="password" placeholder="Ingresar clave"
              [(ngModel)]="modelUser.password" required>
            <mat-icon matSuffix>https</mat-icon>
          </mat-form-field>
          <div class="botonDiv">
            <button color="primary" style="width: 100%;" (click)="login()" mat-raised-button color="primary">Iniciar
              Sesión</button>
          </div>
        </div>
        
       </div>
    </mat-card>

  <!--  <mat-card class="cardLogin" style="width: 400px;">
      <br>
        <h2 style="text-align:center">Bienvenido a EscarabajosApp</h2>
        <img  class="center" style="width: 150px;margin-top: 10px;" src="Card" src="assets/images/logo.png">

        <div class="botonDiv">
            <h3 style="text-align:center">Eres?</h3>
            <br>
          <button color="primary" style="width: 100%;" (click)="login()" mat-raised-button color="primary"><mat-icon>assignment_ind</mat-icon><span style="margin-left: 10px;">Administrador</span></button> <br><br>
            <button color="primary" style="width: 100%;" (click)="login()" mat-raised-button color="primary"><mat-icon>directions_bike</mat-icon><span style="margin-left: 10px;">Ciclista</span></button>
        </div>
      </mat-card>-->
  </div>