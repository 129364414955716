import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import {LoginComponent} from '../login/login.component';
export const routes: Routes = [
    { path: '', component: LoginComponent, pathMatch: 'full' }
  ];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    SharedModule,
    HttpClientModule
  ],
  declarations: [
    LoginComponent
  ]
})
export class LoginModule { }
