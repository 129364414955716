import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-obsertion-dialog',
  templateUrl: './obsertion-dialog.component.html',
  styleUrls: ['./obsertion-dialog.component.scss']
})
export class ObsertionDialogComponent implements OnInit {

  value = '';

  constructor(public dialogRef: MatDialogRef<ObsertionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    if(this.data.observacion)
      this.value = this.data.observacion;
  }

  onConfirm(): void {
    this.dialogRef.close(this.value);
  }

  onDismiss(): void {
    this.dialogRef.close(null);
  }
}
