<mat-form-field style="width: 300px;float: right;margin-top: -10px;margin-right: 30px;">
    <mat-label>Buscar</mat-label>
    <input  matInput (keyup)="applyFilter($event)">
  </mat-form-field>

<div class="mat-elevation-z8">
  <div class="scroll">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"> 
    <ng-container *ngFor="let column of displayedColumnsAux; let i = index" matColumnDef="{{column.fieldLabel}}">
      <th [id]="i"   mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="column.currency ? 'right-text' : ''">{{column.fieldName}}</th>
      <td  mat-cell *matCellDef="let element" (click)="selectRow(element)" [ngClass]="column.currency ? 'right-text' : ''"> {{changeState(element[column.fieldLabel],column.fieldLabel, column.filters, column)}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  </div>
 </div> 