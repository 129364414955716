import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { DataTableModel } from './data-table-model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterContentInit {

  constructor() { }

  public list: Array<any> = []

  @Input() dataSource: any;
  @Input() api: Boolean = false;
  @Input() displayedColumns: DataTableModel[];
  displayedColumnsAux: any[];
  @Output() itemSelected = new EventEmitter<any>();
  @Output() sortSelected = new EventEmitter<any>();

  @ViewChild(MatSort, {static: true}) sort2: MatSort;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterContentInit() {
    this.displayedColumnsAux = this.displayedColumns;
    this.displayedColumns = this.displayedColumns.map(value => value.fieldLabel);

  }

  sortData(sort: Sort) {
    if (!this.api) {
      const data = this.dataSource.data.slice();
      this.dataSource.data = data.sort((a, b) => {
        const aValue = (a as any)[sort.active];
        const bValue = (b as any)[sort.active];
        return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    } else {
      this.sortSelected.emit(this.sort2)
    }
  }

  public selectRow(value: any) {
    this.itemSelected.emit(value);
  }

  public changeState(info: any, data: any, filters?: any, column?: any): string {    
    /**
     * Se agrega el filters para poder llegar a los valores que no estan en el primer nivel
     */
    if (column.currency) {
      return Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(info);
    }
    if (info != 1 && info != 2 && info != 0) {
      if(filters) {
        filters = filters.split('.');
        filters.forEach((filter: string) => {
          info = info[filter];
        })
      }
      return info;
    }else {
      if (data == 'tipo' && info != null) {
        return info == 1 ? 'mensaje' : 'texto';
      }
    }

    if ((data == 'estaActivo' || data == 'estado') && info != null) {
      return info == 1 ? 'Activo' : 'Inactivo';
    }

    if ((data == 'interna' ) && info != null) {
      return info == 1 ? 'Internas' : 'Externas';
    }
    return info
  }

}