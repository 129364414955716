<mat-card class="card">
  <mat-card-header>
    <mat-card-title style="margin-top: 15px;">{{data?.title}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{data?.label}}</mat-label>
        <textarea matInput [(ngModel)]="value" style="min-height: 200px; height: 100%; width: 100%"> </textarea>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button [disabled]="!value || value.length === 0" color="primary" style="float: right;margin-left: 20px;"  (click)="onConfirm()">{{data?.confirmButton}}</button>
    <button mat-raised-button color="warn" (click)="onDismiss()">{{data?.cancelButton}}</button>
  </mat-card-actions>
</mat-card>
